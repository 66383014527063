html,
body,
div {
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  padding: var(--spacing-xs);
}

.css-ywccx7-control,
.css-pbretc-control {
  height: 100%;
}
/*fix conflict tailwind and forma-36 /// end */
